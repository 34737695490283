var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('InfoBanner',{attrs:{"module_name":this.module_name}}),_c('PageTitle',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Проекты ")]},proxy:true},{key:"custom-btn",fn:function(){return [_c('v-btn',{attrs:{"color":"accent darken-4","type":"submit","depressed":"","to":_vm.go_to}},[_vm._v(" Создать проект ")])]},proxy:true}])}),(_vm.info_message())?_c('InfoAlert',{staticClass:"mb-6",attrs:{"is_dark":""},scopedSlots:_vm._u([{key:"alert_text",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.info_message())}})]},proxy:true}],null,false,631939790)}):_vm._e(),_c('v-card',{class:_vm.$style.card},[_c('Filters',{on:{"filter_update":_vm.filter_update},scopedSlots:_vm._u([{key:"btn-outer",fn:function(){return [_c('v-btn',{class:[_vm.$style.btnOuter , 'mb-0'],attrs:{"text":""},on:{"click":function($event){_vm.cols_dialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tune")]),_c('span',[_vm._v("Настройка полей")])],1)]},proxy:true}])}),_vm._l((_vm.filtered_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),_c('Table',{key:("projects + " + _vm.table_key),class:_vm.$style.table,attrs:{"headers":_vm.filtered_cols,"items":_vm.projects,"options":_vm.table_options,"custom-sort":_vm.prevent_sort,"loading":_vm.projects_loading,"no-data-text":"Проекты, удовлетворяющие выбранным параметрам, отсутствуют","hide-default-footer":""},on:{"update:options":function($event){_vm.table_options=$event},"click:row":_vm.row_click_handler},scopedSlots:_vm._u([{key:"item.external_number",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.external_number)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.$style.textWrap},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("date")(item.created_at))+" ")])]}},{key:"item.total_amount",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.total_amount))+" ")])]}},{key:"item.shipment_at",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("date")(item.shipment_at))+" ")])]}},{key:"item.closed_at",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.closed_at)+" ")])]}},{key:"item.organization",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.organization)+" ")])]}},{key:"item.partner_contact_person",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.partner_contact_person)+" ")])]}},{key:"item.contacts",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('div',{class:{'d-flex align-center': !_vm.is_mobile}},[(item.partner_contact_telephone)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.partner_contact_telephone)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.partner_contact_telephone)+" ")])],1),_c('div',{class:{'d-flex align-center': !_vm.is_mobile}},[(item.partner_contact_email)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.partner_contact_email)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.partner_contact_email)+" ")])],1)])]}},{key:"item.subpartner",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.subpartner)+" ")])]}},{key:"item.sale_points_quantity",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.sale_points_quantity)+" ")])]}},{key:"item.client_name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.$style.textWrap},[_vm._v(" "+_vm._s(item.client_name)+" ")])]}},{key:"item.products_quantity",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.products_quantity)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ChipStatus',_vm._g(_vm._b({class:_vm.$style.chip,attrs:{"status":_vm.get_project_status(item)}},'ChipStatus',attrs,false),on),[_vm._v(" "+_vm._s(item.status ? item.status.name : '')+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.get_project_status_tooltip(item))+" ")])])]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true)})],2),_c('CustomColsDialog',{attrs:{"cols":_vm.cols,"cols_list":_vm.cols,"storage_name":_vm.cols_storage_name,"default_cols":_vm.cols_default},on:{"update:cols_list":function($event){_vm.cols=$event},"save_cols":_vm.save_cols,"cancel_dialog":function($event){_vm.cols_dialog = false},"close":function($event){_vm.cols_dialog = false}},model:{value:(_vm.cols_dialog),callback:function ($$v) {_vm.cols_dialog=$$v},expression:"cols_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }